<template>
  <div class="profile">
    <!-- <v-divider style="width: 100%" /> -->
    <v-layout>
      <v-flex xs4>
        <h1 class="page-title">Lead Feed</h1>
      </v-flex>

      <v-flex xs8>
        <v-layout row style="justify-content: space-between">
          <v-flex xs3>
            <label>Search ID By</label>
            <CRSelect
              :id="`lead-feed-broker-select-search-id-change`"
              v-model="searchIdType"
              :items="['Quote', 'Reservation']"
              solo
              flat
              :background-color="$cr.theme.white"
              color="primary"
              style="max-width: 210px"
              @change="(evt) => searchForId()"
            />
          </v-flex>
          <v-flex xs3>
            <CRInput
              :id="`lead-feed-broker-text-field-search`"
              v-model="search"
              solo
              flat
              label="Search ID"
              append-icon="$vuetify.icons.search"
              :background-color="$cr.theme.white"
              style="max-width: 210px"
            />
          </v-flex>
          <v-flex xs3>
            <label>Filter Market</label>
            <LeadMultimarket
              :id="`lead-feed-broker`"
              class="multimarket"
              :set-filter="augmentFiltersWithMarket"
              :background="$cr.theme.white"
              style="max-width: 210px"
            />
          </v-flex>
          <v-flex xs3>
            <label>Sort by</label>
            <CRSelect
              :id="`lead-feed-broker-select-sort-change`"
              :items="sortItems"
              solo
              flat
              :value="sortValue"
              :background-color="$cr.theme.white"
              color="primary"
              style="max-width: 210px"
              @change="(evt) => handleSortChange(evt)"
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <div>
      <v-tabs class="tabs-setting">
        <v-tab
          :id="`lead-feed-broker-tab-view-all`"
          @click="(evt) => activateTab('blue')"
        >
          All
        </v-tab>
        <v-tab
          :id="`lead-feed-broker-tab-view-ending-soon`"
          @click="(evt) => activateTab('orange')"
        >
          Ending Soon
        </v-tab>
        <v-tab
          :id="`lead-feed-broker-tab-view-expired`"
          @click="(evt) => activateTab('red')"
        >
          Expired
        </v-tab>
      </v-tabs>

      <div style="overflow: hidden">
        <div style="overflow: scroll">
          <div style="min-width: 1280px">
            <template v-for="(row, index) in projectedRows">
              <div
                :key="index"
                class="data-row"
                :class="{ expanded: isExpanded[row.tripId] }"
                :style="`
                  border-left: 3px solid ${colorCodeItem(row.biddingEndDate)};
                  border-top: 1px solid ${colorCodeItem(row.biddingEndDate)};
                  border-bottom: 1px solid ${colorCodeItem(row.biddingEndDate)};
                  border-right: 1px solid ${colorCodeItem(row.biddingEndDate)};
                `"
              >
                <div
                  :id="`lead-feed-broker-toggle-expansion-row-${index}`"
                  class="data-display"
                  style="background-attachment: fixed"
                  @click="(evt) => toggle(row.tripId)"
                >
                  <!-- <v-layout class="header-container flex-row wrap"> -->
                  <v-layout row align-center justify-space-between>
                    <!-- Time Counter -->
                    <v-flex style="width: 8%">
                      <v-layout row align-center justify-start>
                        <v-flex shrink>
                          <CRIcon
                            :style="`margin-right: 5px; color: ${colorCodeItem(
                              row.biddingEndDate
                            )}`"
                          >
                            time
                          </CRIcon>
                        </v-flex>
                        <v-flex shrink>
                          <span
                            :style="`color: ${colorCodeItem(
                              row.biddingEndDate
                            )}`"
                          >
                            {{
                              calculateDifference(row.biddingEndDate) ||
                              `Ended on ${datewithOrdinal(row.biddingEndDate)}`
                            }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex style="width: 14%">
                      <v-chip style="width: 100%">
                        <v-flex>
                          <v-layout row align-center>
                            <CRIcon class="icon-centered" color="primary">
                              customer
                            </CRIcon>
                            <v-layout column>
                              {{
                                row.customerFirstName +
                                ' ' +
                                row.customerLastName
                              }}
                              <router-link
                                v-if="row.reservationId"
                                :id="`lead-feed-broker-link-reservation-row-${index}`"
                                target="_blank"
                                :to="{
                                  path: `/reservations/${row.reservationId}`,
                                }"
                                @click="(event) => event.stopPropagation()"
                              >
                                #{{ row.managedId }}
                              </router-link>
                              <router-link
                                v-else
                                :id="`lead-feed-broker-link-quote-row-${index}`"
                                target="_blank"
                                :to="{ path: `/quotes/view/${row.quoteId}` }"
                                @click="(event) => event.stopPropagation()"
                              >
                                #{{ row.quoteId }}
                              </router-link>
                            </v-layout>
                          </v-layout>
                        </v-flex>
                      </v-chip>
                    </v-flex>

                    <!-- Num Bids -->
                    <v-flex style="width: 9%">
                      <v-chip style="width: 100%">
                        <v-layout row align-center>
                          <CRIcon color="primary" class="icon-centered">
                            affiliate_management
                          </CRIcon>
                          <v-layout column>
                            <div>
                              {{
                                row.bids.filter((bi) =>
                                  bi.bidStatus
                                    ? bi.bidStatus !== 'rejected'
                                    : true
                                ).length
                              }}
                              {{
                                pluralize(
                                  row.bids.filter((bi) =>
                                    bi.bidStatus
                                      ? bi.bidStatus !== 'rejected'
                                      : true
                                  ).length,
                                  'Bid'
                                )
                              }}
                            </div>
                            <div>
                              {{
                                row.referrals &&
                                row.referrals.filter(
                                  (ref) =>
                                    ref.reservationStatusKey !== 'cancelled'
                                ).length
                              }}
                              {{
                                row.referrals &&
                                pluralize(
                                  row.referrals.filter(
                                    (ref) =>
                                      ref.reservationStatusKey !== 'cancelled'
                                  ).length,
                                  'Referral'
                                )
                              }}
                            </div>
                          </v-layout>
                        </v-layout>
                      </v-chip>
                    </v-flex>

                    <v-flex style="width: 14%">
                      <v-chip style="width: 100%">
                        <v-layout row align-center>
                          <CRIcon
                            color="primary"
                            class="icon-centered"
                            icon-name="Date Single"
                          >
                            date_single
                          </CRIcon>
                          <v-layout column>
                            <div>
                              &nbsp;Due By:
                              {{ dateAsISODate(row.biddingEndDate) }}
                            </div>
                            <div>PU Date: {{ rowPickupDate(row) }}</div>
                          </v-layout>
                        </v-layout>
                      </v-chip>
                    </v-flex>

                    <v-flex style="width: 11%">
                      <v-chip style="width: 100%">
                        <v-layout row align-center>
                          <CRIcon color="primary" class="icon-centered">
                            dollar
                          </CRIcon>
                          <v-layout column>
                            <div>Total: ${{ row.total }}</div>
                            <div>Max: ${{ row.maxBidAmount }}</div>
                          </v-layout>
                        </v-layout>
                      </v-chip>
                    </v-flex>

                    <v-flex style="width: 14%">
                      <v-chip style="width: 100%">
                        <v-layout row align-center>
                          <CRIcon color="primary" class="icon-centered">
                            location
                          </CRIcon>
                          <v-layout column>
                            <div>
                              {{
                                row.stops && row.stops.length > 0
                                  ? row.stops[0].address.completeAddress
                                  : ''
                              }}
                            </div>
                            <div>{{ row.passengerCount }} Passengers</div>
                          </v-layout>
                        </v-layout>
                      </v-chip>
                    </v-flex>

                    <v-flex style="width: 9%">
                      <v-chip style="width: 100%">
                        <v-layout row align-center>
                          <CRIcon color="primary" class="icon-centered">
                            drivers_circle
                          </CRIcon>
                          <v-layout column>
                            <div>
                              {{ row.requiredDrivers }}
                              {{ pluralize(row.requiredDrivers, 'Driver') }}
                            </div>
                            <div>
                              {{ calcNumVehicles(row.requiredVehicles) }}
                              {{
                                pluralize(
                                  calcNumVehicles(row.requiredVehicles),
                                  'Vehicle'
                                )
                              }}
                            </div>
                          </v-layout>
                        </v-layout>
                      </v-chip>
                    </v-flex>

                    <v-flex style="width: 14%">
                      <v-layout
                        row
                        align-center
                        justify-center
                        style="width: 100%"
                      >
                        <v-flex
                          :id="`lead-feed-broker-link-close-prompt-row-${index}`"
                          class="close-box"
                          shrink
                          @click="(evt) => toggleClosePrompt(evt, row)"
                        >
                          <v-btn class="btn-secondaryaction">
                            Close Bidding
                          </v-btn>
                        </v-flex>

                        <v-flex class="arrow-box" shrink>
                          <CRIcon
                            v-if="isExpanded[row.tripId]"
                            color="lightGray"
                            :width="32"
                            :height="32"
                            view-box="0 0 24 24"
                            icon-name="arrow-up"
                          >
                            keyboard_arrow_up
                          </CRIcon>
                          <CRIcon
                            v-else
                            color="lightGray"
                            :width="32"
                            :height="32"
                            view-box="0 0 24 24"
                            icon-name="arrow-down"
                          >
                            keyboard_arrow_down
                          </CRIcon>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
                <div class="quick-display">
                  <transition name="slide-fade">
                    <LeadFeedQuick
                      v-if="
                        isExpanded[row.tripId] && !closeBidToggle[row.tripId]
                      "
                      :id="`lead-feed-broker`"
                      :row="row"
                      :collapse="() => collapse(row.tripId)"
                      :tab-selection="tabSelection"
                    />

                    <div
                      v-if="closeBidToggle[row.tripId]"
                      class="close-prompt-container"
                    >
                      <div class="close-prompt">
                        <div class="headline">Close Bidding</div>
                        <div>
                          Are you sure you would like to remove this reservation
                          from bidding? All unaccepted bids will be lost and
                          this cannot be undone.
                        </div>
                        <div class="btn-container">
                          <v-btn
                            :id="`lead-feed-broker-button-toggle-close-prompt-row-${index}`"
                            class="btn-secondaryaction"
                            @click="(evt) => toggleClosePrompt(evt, row)"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            :id="`lead-feed-broker-button-toggle-close-bidding-row-${index}`"
                            class="delete-target"
                            @click="(evt) => closeBidding(evt, row)"
                          >
                            Close Bidding
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="bid-pagination">
      <v-pagination
        :id="`lead-feed-broker-pagination-choose-page`"
        v-model="currentPage"
        :length="parseInt(totalPages, 10)"
        :total-visible="5"
        class="elevation-0"
        @input="(page) => (currentPage = page)"
      />
    </div>
  </div>
</template>

<script>
import LeadFeedQuick from '../components/LeadFeedQuick.vue'
import { pluralize, getOrdinalForNumber } from '@/utils/string'
import { DateTime } from 'luxon'
import LeadMultimarket from '../components/ModifiedLeadMultimarkets.vue'
import { sort } from '@/utils/sort'
import { filter } from '@/utils/filter'

const miliDay = 86400000
const miliHour = 3600000
const miliMin = 60000

export default {
  metaInfo: {
    title: 'Lead Feed',
  },
  components: {
    LeadFeedQuick,
    LeadMultimarket,
  },
  data() {
    return {
      searchIdType: 'Reservation',
      reservationStatusMap: [
        { text: 'Upcoming', value: 'upcoming' },
        { text: 'Started', value: 'started' },
        { text: 'Finished', value: 'finished' },
        { text: 'Cancelled', value: 'cancelled' },
        { text: 'Hold', value: 'hold' },
      ],
      rows: [],
      projectedRows: [],
      totalPages: 1,
      hasSearched: false,
      currentPage: 1,
      isExpanded: {},
      closeBidToggle: {},
      tabSelection: 'blue',
      currentMarketSearch: [],
      search: null,
      isQuote: false,
      sortItems: [
        {
          value: 0,
          text: 'Newest',
        },
        {
          value: 1,
          text: 'Ending Soonest',
        },
        {
          value: 2,
          text: 'Pickup Date Soonest',
        },
        // {
        //   value: 3,
        //   text: 'In Market',
        // },
        {
          value: 4,
          text: 'Most Drivers',
        },
      ],
      sortValue: 0,
      sortType: 0,
    }
  },
  watch: {
    currentPage() {
      this.loadTripsOpenForBid(this.currentPage)
    },
    search() {
      this.searchForId()
    },
  },
  async mounted() {
    this.activateTab('blue')
    this.isQuote = !this.reservationId
  },
  methods: {
    pluralize,
    datewithOrdinal(date) {
      const datetime = DateTime.fromISO(date)
      return `${datetime.toFormat('MMM')} ${getOrdinalForNumber(
        datetime.day
      )} ${datetime.toFormat('yy')}`
    },
    dateAsISODate(date) {
      return DateTime.fromISO(date).toISODate()
    },
    async searchForId() {
      if (this.searchIdDebounce) {
        clearTimeout(this.searchIdDebounce)
      }

      this.searchIdDebounce = setTimeout(async () => {
        await this.loadTripsOpenForBid()
      }, 300)
    },
    augmentFiltersWithMarket(marketArr) {
      if (
        JSON.stringify(this.currentMarketSearch) === JSON.stringify(marketArr)
      )
        return
      this.currentMarketSearch = marketArr
      this.loadTripsOpenForBid()
    },
    toggle(id) {
      this.isExpanded[id] = !this.isExpanded[id]

      if (!this.isExpanded[id]) {
        this.closeBidToggle[id] = false
      }

      this.$forceUpdate()
    },
    handleSortChange(event) {
      this.sortType = event
      this.loadTripsOpenForBid()
    },
    toggleClosePrompt(evt, data) {
      evt?.stopPropagation()
      evt?.preventDefault()
      this.closeBidToggle[data.tripId] = !this.closeBidToggle[data.tripId]

      if (!this.isExpanded[data.tripId]) {
        this.toggle(data.tripId)
      }
      this.$forceUpdate()
    },
    async closeBidding(evt, data) {
      try {
        await this.$store.dispatch('trips/modifyBidTrip', {
          tripId: data.tripId,
          payload: {
            openForBid: false,
          },
        })
      } catch (e) {
        throw new Error(e)
      }

      await this.sync()

      this.toggleClosePrompt(evt, data)
    },
    calcNumVehicles(requiredVehicles) {
      return requiredVehicles.reduce((acc, veh) => {
        return acc + veh.requiredVehicles
      }, 0)
    },
    sortProps() {
      switch (this.sortType) {
        case 0:
          return {
            prop: 'bidStart',
            direction: 'desc',
          }
        case 1:
          return {
            prop: 'biddingEndDate',
            direction: 'asc',
          }
        case 2:
          return {
            prop: 'startDate',
            direction: 'asc',
          }
        // case 3:
        //   return {
        //     prop: `${this.activeBidStatusValue === 'open' ? '' : 'trip'}startDate`,
        //     direction: 'desc',
        //   }
        case 4:
          return {
            prop: 'requiredDrivers',
            direction: 'desc',
          }
        default:
          return {}
      }
    },
    async loadTripsOpenForBid() {
      let flag = null

      if (this.tabSelection === 'blue') {
        flag = 'filterAll'
      }

      if (this.tabSelection === 'orange') {
        flag = 'filterEndingSoon'
      }

      if (this.tabSelection === 'red') {
        flag = 'filterExpired'
      }
      const params = {
        pageSize: 20,
        page: this.currentPage,
        markets: this.currentMarketSearch,
        flag: [flag],
      }

      if (this.search) {
        const idFilter = filter()
        idFilter.clear()
        const parentFilter = idFilter.createParent('or')
        if (this.searchIdType === 'Quote') {
          const quoteFilterObject = {
            column: {
              _t_id: 'lead_feed_quote_id_filter',
              prop: 'quote/quoteId',
              filterType: 'eq',
            },
            value: this.search,
          }
          idFilter.add(parentFilter, quoteFilterObject)
        }
        if (this.searchIdType === 'Reservation') {
          const resFilterObject = {
            column: {
              _t_id: 'lead_feed_res_id_filter',
              prop: 'reservations/managedId',
              filterType: 'eq',
            },
            value: this.search,
          }
          idFilter.add(parentFilter, resFilterObject)
        }

        params.filters = idFilter.asQueryParams()
      }

      if (this.currentMarketSearch.length < 1) {
        params.flag.push('filterAllTripMarkets')
      }

      if (!isNaN(this.sortType)) {
        const sortType = sort()
        sortType.add({
          ...this.sortProps(),
        })

        params.sorts = sortType.asQueryParams()
      }
      const data = await this.$store
        .dispatch('trips/getTrips', params)
        .then(({ data }) => {
          return data
        })
        .catch((e) => {})

      this.totalPages = Math.ceil(data ? data.count / 20 : 1 / 20)
      this.rows = data.resultList

      this.projectedRows = data.resultList

      // FIXME: Ideally the server won't required this sorting.
      this.projectedRows.forEach((row) => {
        row.stops.sort((a, b) => {
          if (a.orderIndex === b.orderIndex) {
            return 0
          }
          return a.orderIndex < b.orderIndex ? -1 : 1
        })
      })

      return this.rows
    },
    colorCodeItem(timestamp) {
      const timeInMil = new Date(timestamp).getTime() - new Date().getTime()

      if (timeInMil >= miliHour && timeInMil >= miliDay) {
        return this.$cr.theme.primary
      }

      if (timeInMil >= 0 && timeInMil <= miliDay) {
        return this.$cr.theme.warning
      }

      if (timeInMil < 0) {
        return this.$cr.theme.error
      }
    },
    calculateDifference(timestamp) {
      const timeInMil = new Date(timestamp).getTime() - new Date().getTime()
      let str = ''

      const days = parseInt(timeInMil / miliDay, 10)
      const remainderFromDays = timeInMil - days * miliDay

      if (days > 0) {
        if (days > 0 && days < 10) {
          str += `0${days}d`
        } else {
          str += `${days}d`
        }
      }

      const hours = parseInt(remainderFromDays / miliHour, 10)
      const remainderFromHours = remainderFromDays - hours * miliHour

      if (hours <= 9 && hours >= 0) {
        str += ` 0${hours}`
      }

      if (hours > 9) {
        str += ` ${hours}`
      }

      str += ':'

      const minutes = parseInt(remainderFromHours / miliMin, 10)

      if (minutes <= 9 && minutes >= 0) {
        str += `0${minutes}`
      }

      if (minutes > 9) {
        str += minutes
      }

      return str.length > 1 ? str : null
    },
    async collapse(id) {
      this.isExpanded[id] = false
      this.$forceUpdate()
      await this.sync()
    },
    async sync() {
      const trips = await this.loadTripsOpenForBid(this.currentPage)

      this.rows = trips
      this.projectedRows.forEach((trip) => {
        this.isExpanded[trip.tripId] = this.isExpanded[trip.tripId] || false
      })

      this.$forceUpdate()
    },
    activateTab(tab) {
      this.tabSelection = tab
      this.projectedRows = []
      this.sync()
    },
    rowPickupDate(row) {
      return row?.stops?.[0]?.pickupDate?.split('T')[0] || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.fixed-width {
  min-width: 250px;
  max-width: 450px;
}

.bid-pagination {
  display: flex;
  justify-content: flex-end;
}

.data-row {
  position: relative;
  max-height: 70px;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: $white;
  transition: max-height 0.7s ease-out;

  &.expanded {
    max-height: 380px;
    padding-bottom: 5px;
  }

  .quick-display {
    max-height: 0;
    transition: max-height 0.7s ease-out;
  }

  &.expanded .quick-display {
    max-height: 300px;
  }
}

.data-display {
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 2%;
  > .layout {
    > .flex {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  .marquee {
    flex-grow: 40;
  }

  .referals {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 16%;
    padding-right: 1.5%;
  }

  .v-chip {
    height: 50px;
    border-radius: 50px;
    background-color: $blue-light;

    .icon-centered {
      margin-right: 6px !important;
    }
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.tabs-setting {
  border-bottom: 1px solid $gray-light;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.arrow-box {
  position: relative;
  right: 1.1%;
}

.dark {
  background-color: $blue-light;
}

.light {
  background-color: $primary;
}

.profile {
  position: relative;
  padding: 24px;
  margin: 0 24px 48px 24px;
  background: $blue-light;
  border-radius: 15px;
}

.header-container {
  display: flex;
  height: 70px;

  > * {
    display: flex;
    flex: 0 1 20%;
    align-items: center;
    height: 24px;
  }

  > * .v-icon {
    border: 1px solid red;
    margin-right: 6px;
    font-size: 18px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.close-prompt-container {
  padding-bottom: 5px;
  background: $white;

  > .close-prompt {
    > * {
      margin-bottom: 2%;
    }

    width: 500px;
    padding-top: 15px;
    padding-bottom: 10px;
    margin-left: 4%;
  }
}

::v-deep.v-tabs .v-tabs__wrapper .v-tabs__container {
  background-color: $blue-light;
}

.close-box {
  position: relative;
  right: 5%;
  z-index: 100;
}

.delete-target {
  color: $white;
  background-color: $red !important;
}

.cancel-target {
  color: $black;
  background-color: $background-hover !important;
}

.btn-container {
  display: flex;
  margin-left: -10px;
}

.page-title {
  margin-top: 7px;
  font-size: 23px;
}

::v-deep .radius-selector {
  max-width: 100%;
}
</style>
