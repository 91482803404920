<template>
  <div>
    <v-layout class="bids-display" column>
      <v-layout
        v-for="(mappedBid, bindex) in bids"
        :key="bindex"
        row
        class="bids-child"
      >
        <v-flex class="bid-cell" xs4>
          <v-layout column>
            <span>
              <b>
                {{
                  bidData[mappedBid.id].dbaName
                    ? bidData[mappedBid.id].dbaName
                    : mappedBid.affiliate.name
                }}
              </b>
            </span>
            <v-chip class="preferred-type" label>
              {{
                capitalize(
                  mappedBid.affiliate.partnerTypeLabel === 'preferred'
                    ? 'certified'
                    : mappedBid.affiliate.partnerTypeLabel
                )
              }}
            </v-chip>
            <div>
              <div>
                <a
                  :id="`${id}-lead-feed-quick-link-affiliate-phone-index-${bindex}`"
                  :href="`tel:${mappedBid.affiliate.phone}`"
                >
                  {{ mappedBid.affiliate.phone }}
                </a>
              </div>
              <div>
                <a
                  :id="`${id}-lead-feed-quick-link-affiliate-email-index-${bindex}`"
                  :href="`mailto:${mappedBid.affiliate.email}`"
                >
                  {{ mappedBid.affiliate.email }}
                </a>
              </div>
            </div>
            <div class="company-row">
              <div styles="margin-bottom: 1px;">
                <img class="star-half" src="@/assets/images/star_rated.svg" />
                <img class="star-half" src="@/assets/images/star_rated.svg" />
                <img class="star-half" src="@/assets/images/star_rated.svg" />
                <img class="star-half" src="@/assets/images/star_half.svg" />
                <img class="star-half" src="@/assets/images/star_no.svg" />
              </div>
            </div>
          </v-layout>
        </v-flex>

        <v-flex class="bid-cell" xs3>
          <v-layout column>
            <div>Notes</div>
            <div>{{ mappedBid.bid.providerNotes }}</div>
          </v-layout>
        </v-flex>

        <v-flex class="bid-cell" xs2>
          <v-layout column>
            <div>Passengers</div>
            <div class="input-container">
              <v-text-field
                :id="`${id}-lead-feed-quick-text-field-passenger-count-index-${bindex}`"
                v-model="bidData[mappedBid.id].bidPassengerCount"
                :disabled="!enableEdit[bindex]"
                :class="`center-align  ${
                  !enableEdit[bindex] ? '' : 'is-enabled'
                }`"
                flat
                solo
                type="number"
                class="center-align"
              />
            </div>
          </v-layout>
        </v-flex>

        <v-flex class="bid-cell" xs2>
          <v-layout column>
            <div>Drivers</div>
            <div class="input-container">
              <v-text-field
                :id="`${id}-lead-feed-quick-text-field-driver-count-index-${bindex}`"
                v-model="bidData[mappedBid.id].driverCount"
                :disabled="!enableEdit[bindex]"
                :class="`center-align  ${
                  !enableEdit[bindex] ? '' : 'is-enabled'
                }`"
                flat
                solo
                type="number"
                class="center-align"
              />
            </div>
          </v-layout>
        </v-flex>

        <v-flex class="bid-cell" xs2>
          <v-layout
            v-for="(vehicle, vehicleIndex) in mappedBid.bid.bidVehicles"
            :key="vehicleIndex"
            column
          >
            <div>{{ vehicle.vehicleType }}</div>
            <div class="input-container">
              <v-text-field
                :id="`${id}-lead-feed-quick-text-field-vehicle-quantity-index-${bindex}-${vehicleIndex}`"
                v-model="
                  bidData[mappedBid.id].vehicles[vehicle.vehicleType].quantity
                "
                :disabled="!enableEdit[bindex]"
                flat
                solo
                type="number"
                :class="`center-align  ${
                  !enableEdit[bindex] ? '' : 'is-enabled'
                }`"
              />
            </div>
          </v-layout>
        </v-flex>

        <v-flex class="bid-cell" xs3>
          <v-layout column class="input-container">
            <div>Bid Amount</div>
            <v-text-field
              :id="`${id}-lead-feed-quick-text-field-bid-amount-index-${bindex}`"
              :ref="bindex"
              v-model="bidData[mappedBid.id].bidAmount"
              :disabled="!enableEdit[bindex]"
              flat
              solo
              :class="`center-align  ${
                !enableEdit[bindex] ? '' : 'is-enabled'
              }  ${
                row.maxBidAmount &&
                bidData[mappedBid.id].bidAmount > row.maxBidAmount
                  ? 'over-amount'
                  : ''
              } ${mappedBid.bid.lowest ? 'lowest-amount' : ''}`"
              type="number"
            />
          </v-layout>
        </v-flex>

        <v-flex class="bid-cell" xs3>
          <v-layout>
            <div
              v-if="mappedBid.bid.bidStatus && mappedBid.bid.bidStatus.id === 2"
              class="status-wrapper"
            >
              <v-chip>Accepted</v-chip>
            </div>
            <div
              v-else-if="
                mappedBid.bid.bidStatus && mappedBid.bid.bidStatus.id === 3
              "
              class="status-wrapper"
            >
              <v-chip>Rejected</v-chip>
            </div>
            <div v-else-if="mappedBid.bid.referralId" class="status-wrapper">
              <v-chip>Sent</v-chip>
            </div>
            <div v-else class="options-wrapper">
              <div class="btn-cont">
                <v-btn
                  v-if="!enableEdit[bindex] && displayAcceptButtons"
                  :id="`${id}-lead-feed-quick-button-acceptBid-index-${bindex}`"
                  class="btn-primaryaction"
                  :loading="loading"
                  @click="(evt) => acceptBid(mappedBid, bindex)"
                >
                  {{
                    bidData[mappedBid.id].bidAmount > row.maxBidAmount
                      ? 'Accept'
                      : 'Accept'
                  }}
                </v-btn>

                <v-btn
                  v-if="enableEdit[bindex]"
                  :id="`${id}-lead-feed-quick-button-edit-bid-index-${bindex}`"
                  class="btn-primaryaction"
                  :loading="loading"
                  @click="(evt) => editBid(mappedBid, bindex)"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="enableEdit[bindex]"
                  :id="`${id}-lead-feed-quick-button-cancel-bid-index-${bindex}`"
                  color="gray"
                  outline
                  solo
                  :disabled="alreadyAccepted"
                  class="cancel-btn"
                  @click="(evt) => toggleEdit(bindex)"
                >
                  Cancel
                </v-btn>
              </div>
              <div
                v-if="!enableEdit[bindex] && displayAcceptButtons"
                class="edit"
              >
                <div
                  :id="`${id}-lead-feed-quick-button-reject-bid-index-${bindex}`"
                  class="text-btn edit"
                  @click="(evt) => toggleEdit(bindex)"
                >
                  Edit
                </div>
                <div
                  class="text-btn reject"
                  @click="(evt) => rejectBid(mappedBid.bid)"
                >
                  Reject
                </div>
              </div>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-snackbar
      v-model="displayerr"
      color="error"
      :timeout="0"
      class="errormsg"
      type="error"
      top
    >
      {{ errmsg }}
      <v-btn
        :id="`${id}-lead-feed-quick-button-close-edit-bid-index-${bindex}`"
        dark
        flat
        @click="displayerr = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import affiliates from '@/services/affiliates'
import bids from '@/services/bids'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { capitalize } from '@/utils/string'
import uuid from 'uuid'

const miliDay = 86400000
const miliHour = 3600000
const miliMin = 60000

export default {
  props: {
    row: {
      type: Object,
      default: () => {
        return {}
      },
    },
    tabSelection: {
      type: String,
      default: () => 'blue',
    },
    collapse: {
      type: Function,
      default: () => {
        return function () {}
      },
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      enableEdit: {},
      bids: [],
      affils: {},
      bidData: {},
      alreadyAccepted: null,
      loading: false,
      errmsg: '',
      displayerr: false,
      displayAcceptButtons: false,
    }
  },
  async mounted() {
    this.displayAcceptButtons = !!this.row.reservationId
    this.displayAcceptButtons = true

    const lowestBid = this.row.bids.reduce(
      (acc, bid, idx) => {
        if (acc.lowestVal === null) {
          acc.bidIdx = idx
          acc.lowestVal = bid.bidAmount
        }
        if (bid.bidAmount < acc.lowestVal) {
          acc.bidIdx = idx
          acc.lowestVal = bid.bidAmount
        }
        return acc
      },
      {
        lowestVal: null,
        bidIdx: null,
      }
    )

    if (lowestBid.bidIdx) {
      this.row.bids[lowestBid.bidIdx].lowest =
        this.row.bids[lowestBid.bidIdx] < this.row.maxBidAmount
    }
    this.getBids()
  },
  methods: {
    async getBids() {
      const data = await this.fetch()
      this.bids = data
        .sort(this.sortBids)
        .filter((offering) => offering.affiliate)
      this.bids.forEach((el, idx) => {
        this.enableEdit[idx] = false
      })
    },
    capitalize,
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
    },
    sortBids(a, b) {
      let x
      let y
      if (a.bid.acceptedOn) {
        x = 1
      } else {
        x = 0
      }

      if (b.bid.acceptedOn) {
        y = 1
      } else {
        y = 0
      }
      return y - x
    },
    async findAffiliates(bidsAndReferrals) {
      const affilFilter = filter()
      const affilSort = sort()

      const parent = affilFilter.createParent('or')

      Object.keys(
        bidsAndReferrals.reduce((acc, offering) => {
          acc[offering.companyId] = true
          return acc
        }, {})
      ).forEach((id, idx) => {
        affilFilter.add(parent, {
          column: {
            _t_id: `companyId${idx}`,
            prop: 'companyId',
            filterType: 'eq',
          },
          value: id,
        })
      })

      affilSort.add({
        prop: 'isPreferred',
        direction: 'desc',
      })

      const aff = await affiliates
        .getAffiliates({
          page: 1,
          pageSize: -1,
          filters: affilFilter.asQueryParams(),
          sorts: affilSort.asQueryParams(),
        })
        .then((data) => data.data.resultList)

      return aff
    },
    bidStatusAgainstReferral(ref) {
      if (ref && ref.referralStatus) {
        if (ref.referralStatus === 'rejected') {
          return {
            id: 3,
          }
        }

        if (ref.referralStatus === 'accepted') {
          return {
            id: 2,
          }
        }
      }

      return null
    },
    async fetch() {
      const bidsAndReferrals = [...this.row.bids]
      // convert this out to standard for in ...
      if (this.row.reservationId) {
        const actualReservation = await this.$store
          .dispatch('reservations/reservationv2ById', {
            reservationId: this.row.reservationId,
          })
          .then((data) => data.data)

        if (actualReservation && actualReservation.referredTo) {
          actualReservation.referredTo.forEach((ref) => {
            bidsAndReferrals.push({
              companyId: ref.companyId,
              name: ref.companyName,
              companyType: ref.companyType || 'free',
              phone: ref.companyPhone,
              driverCount: ref.driverCount,
              referralId: ref.reservationId, // ref.reservationId
              bidVehicles: ref.requiredVehicles.map((type) => {
                return {
                  vehicleTypeId: type.vehicleType.id,
                  vehicleType: type.vehicleType.label,
                  requiredVehicles: type.quantity,
                }
              }),
              bidAmount: ref.referralAmount,
              bidStatus: ref.referralStatus,
              bidPassengerCount: ref.referralPassengerCount,
            })
          })
        }
      }

      const affiliates = await this.findAffiliates(bidsAndReferrals)
      const mappedBids = bidsAndReferrals
        .map((offering) => {
          if (offering.acceptedOn) {
            // this.alreadyAccepted = true
          }
          const id = uuid.v4()

          const aff = affiliates.find(
            (aff) => aff.companyId === offering.companyId
          )

          let nearestMarketDBAName = null

          for (const companyDBA in aff.companyDBANames) {
            if (
              aff.companyDBANames[companyDBA].marketId ===
              this.row.nearestMarketId
            ) {
              nearestMarketDBAName = aff.companyDBANames[companyDBA].name
            }
          }

          if (offering.bidVehicles.length === 0) {
            return null
          }
          this.bidData[id] = {
            dbaName: nearestMarketDBAName,
            bidPassengerCount: offering.bidPassengerCount,
            driverCount: offering.driverCount,
            vehicles: offering.bidVehicles.reduce((acc, type) => {
              acc[type.vehicleType] = {
                quantity: type.requiredVehicles,
                vehicleTypeId: type.vehicleTypeId,
                label: type.vehicleType,
              }
              return acc
            }, {}),
            bidAmount: offering.bidAmount,
          }

          return {
            id,
            bid: offering,
            affiliate: aff,
          }
        })
        .filter(Boolean)
      return mappedBids
    },
    toggleEdit(idx) {
      this.enableEdit[idx] = !this.enableEdit[idx]
      this.$forceUpdate()
    },
    calculateDifference(timestamp) {
      const timeInMil = new Date(timestamp).getTime() - new Date().getTime()
      let str = ''

      const days = parseInt(timeInMil / miliDay, 10)
      const remainderFromDays = timeInMil - days * miliDay

      if (days > 0) {
        str += `${days}d`
      }

      const hours = parseInt(remainderFromDays / miliHour, 10)
      const remainderFromHours = remainderFromDays - hours * miliHour

      if (hours <= 9 && hours >= 0) {
        str += ` 0${hours}`
      }

      if (hours > 9) {
        str += ` ${hours}`
      }

      str += ':'

      const minutes = parseInt(remainderFromHours / miliMin, 10)

      if (minutes <= 9 && minutes >= 0) {
        str += ` 0${minutes}`
      }

      if (minutes > 9) {
        str += minutes
      }

      return str
    },
    remapNewCompanyFormatToOld(company) {
      return [
        {
          company: { id: company.companyId },
          is_active: true,
          id: company.affiliateId,
        },
        {
          id: company.companyId,
        },
      ]
    },
    async acceptBid(offering, bindex) {
      this.loading = true
      try {
        const { bid, affiliate } = offering
        const { reservationId } = this.row
        const {
          bidAmount: amount,
          bidVehicles = [],
          driverCount: requiredDrivers,
          bidPassengerCount,
        } = bid
        const { companyId } = affiliate
        const referralVehicles = bidVehicles.map((v) => {
          v.quantity = v.requiredVehicles
          return v
        })

        const offerParams = {
          reservationId,
          companyId,
          amount,
          referralVehicles,
          requiredDrivers,
          referralPassengerCount: bidPassengerCount,
          sendReferralEmail: false,
        }
        await bids.acceptBid(offering.bid.bidId)
        await this.$store.dispatch('reservations/offerReferral', offerParams)
        await this.getBids()

        this.collapse()
        this.loading = false
      } catch (e) {
        this.loading = false
        this.displayErr('Unable to accept bid due to an error.', true)
      }
    },
    async rejectBid(bid) {
      this.loading = true
      try {
        await bids.rejectBid(bid.bidId)
        // eslint-disable-next-line require-atomic-updates
        bid.active = false
        bid.bidStatus.id = 3
        this.loading = false
      } catch (e) {
        this.loading = false
        this.displayErr('Unable to reject a bid due to an error.', true)
      }
    },
    async editBid(fullData, bindex) {
      this.loading = true

      const vehicleList = this.vehicleToList(this.bidData[fullData.id].vehicles)
      if (!this.row.reservationId) {
        try {
          await bids.editBidNoReferral({
            bidId: fullData.bid.bidId,
            bidAmount: this.bidData[fullData.id].bidAmount,
            active: true,
            bidVehicles: vehicleList,
            bidPassengerCount: this.bidData[fullData.id].bidPassengerCount,
            driverCount: this.bidData[fullData.id].driverCount,
          })
          this.collapse()
          this.getBids()
          this.loading = false
        } catch (e) {
          this.loading = false
          this.displayErr(e, true)
        }
      } else {
        this.offerReferral(fullData, bindex)
        this.getBids()
      }
    },
    async offerReferral(fullData, bindex) {
      this.loading = true
      try {
        const amount =
          this.bidData && this.bidData[fullData.id]
            ? parseInt(this.bidData[fullData.id].bidAmount, 10)
            : 0
        const requiredDrivers =
          this.bidData && this.bidData[fullData.id]
            ? parseInt(this.bidData[fullData.id].driverCount, 10)
            : 0
        const bidPassengerCount =
          this.bidData && this.bidData[fullData.id]
            ? parseInt(this.bidData[fullData.id].bidPassengerCount, 10)
            : 0
        const { affiliate } = fullData
        const { reservationId } = this.row
        const { companyId } = affiliate
        const referralVehicles = []
        this.row.requiredVehicles.forEach((requiredVehicle) => {
          const vehicleTypeId = requiredVehicle.vehicleTypeId
          const quantity = this.bidData[fullData.id].vehicles[
            requiredVehicle.vehicleType
          ]
            ? this.bidData[fullData.id].vehicles[requiredVehicle.vehicleType]
                .quantity
            : 0
          referralVehicles.push({
            quantity,
            vehicleTypeId,
          })
        })
        const offerParams = {
          reservationId,
          companyId,
          amount,
          referralVehicles,
          requiredDrivers,
          referralPassengerCount: bidPassengerCount,
        }

        await bids.rejectBid(fullData.bid.bidId)
        await this.$store.dispatch('reservations/offerReferral', offerParams)
        this.collapse()

        // // eslint-disable-next-line require-atomic-updates
        // fullData.bid.referralId = referralId
        this.loading = false
      } catch (e) {
        this.loading = false
        this.displayErr(e, true)
      }
    },
    vehicleToList(vehicles) {
      const listVehicles = []
      for (const vehicle in vehicles) {
        const tempVehicle = {
          vehicleType: {
            id: vehicles[vehicle].vehicleTypeId,
            label: vehicles[vehicle].label,
            key: null,
            description: null,
          },
          quantity: vehicles[vehicle].quantity,
        }
        listVehicles.push(tempVehicle)
      }
      return listVehicles
    },
  },
}
</script>

<style lang="scss" scoped>
.star-half {
  width: 20px;
}

.arrow-box {
  flex-grow: 1;
  padding-top: 1%;
}

.bids-display {
  display: block;
  width: 98%;
  min-height: 100px;
  max-height: 300px;
  padding-left: 1%;
  overflow-y: scroll;

  .bid-cell {
    padding: 10px;
  }

  .bids-child {
    width: 99%;
    padding-top: 1.2%;
    padding-bottom: 1%;
    margin-bottom: 2px;
    background-color: $blue-light;
    border-radius: 15px;

    .status-wrapper {
      width: 50%;
      padding-top: 21px;
      padding-bottom: 1%;
      margin-bottom: 2px;
      border-radius: 15px;
      text-align: center;
    }

    .options-wrapper {
      padding: 10px;
      padding-top: 16px;
      .edit {
        display: flex;
        flex-direction: column;
        align-items: center;
        .text-btn {
          cursor: pointer;
          margin-top: 8px;

          &.edit {
            color: $blue;
          }

          &.reject {
            color: $red;
          }
        }
      }
    }

    .vehicle-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 4%;
      padding-left: 4%;
      margin-bottom: 2%;

      .input-container {
        width: 30%;
        height: 40px;
        margin-left: 3%;
      }
    }
  }
}

.bids-display::-webkit-scrollbar {
  width: 0.5%;
}

.bids-display::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 20px;
}

.center-align::v-deep input {
  text-align: center;
}

::v-deep textarea {
  resize: none;
}

.over-amount ::v-deep .v-input__control .v-input__slot {
  background-color: $danger !important;

  input {
    color: $white;
  }
}

.lowest-amount ::v-deep .v-input__control .v-input__slot {
  background-color: $green !important;

  input {
    color: $white;
  }
}

.is-enabled ::v-deep .v-input__control .v-input__slot {
  background-color: $gray-light !important;
}

.is-enabled.over-amount ::v-deep .v-input__control .v-input__slot {
  background-color: $danger !important;

  input {
    color: $white;
  }
}

.is-enabled.lowest-amount ::v-deep .v-input__control .v-input__slot {
  background-color: $green !important;

  input {
    color: $white;
  }
}

.company-type::v-deep.v-chip {
  display: flex;
  justify-content: center;
  width: 20%;
  margin-top: 0;
  margin-left: 0;
  color: $white;
  background-color: $gray-mid-light;
}

.preferred-type::v-deep.v-chip {
  display: flex;
  justify-content: left;
  margin-top: 0;
  margin-left: 0;
  width: fit-content;
}

.medium-input::v-deep input[type='number'] {
  text-align: center;
}

@media only screen and (min-width: 1470px) {
  .arrow-box {
    padding-left: 0.5%;
  }
}
</style>
